@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: sticky;
  top: 0;

  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    /* padding-top: 3em; */
    /* padding-top: calc(var(--topbarHeightDesktop) + 3em); */
    /* padding-top: calc(var(--topbarHeightDesktop) + var(--categoriesTopbarHeightDesktop)); */
    padding-top: 0;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.fullContainer {
  display: unset;
}

.hideForMap {
  & > div:first-child {
    display: none;
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;
  display: none;
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  margin-top: 24px;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: fixed; */
    position: sticky;
    top: calc(var(--topbarHeightDesktop) + var(--categoriesTopbarHeightDesktop));
    right: 0;

    /* Fixed content needs width relative to viewport */
    /* width: 50vw; */
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    /* width: 37.5vw; */
  }
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
  float: right;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.searchFooter {
  z-index: 1;

  & > footer {
    margin-top: 0 !important;
  }
}

.categoriesContainer {
  text-align: center;
  background-size: cover;
  padding: 20px 0 0;
  margin-top: 0;
  max-height: unset;

  @media(--viewportMedium) {
    padding: 0;
    margin-top: calc(var(--topbarHeightDesktop));
    max-height: var(--categoriesTopbarHeightDesktop);
  }

  & > div {
    display: flex;
    width: 100vw;
    margin: 0 auto;
    flex-direction: column;

    @media(--viewportMedium) {
      width: 90vw;
      flex-direction: row;
    }

    & > div {
      width: 100%;
    }

    & > div:first-child {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 90vw;
      margin: 0 auto;

      @media(--viewportMedium) {
        padding: 40px 0;
      }

      & > div:first-child {
        color:  var(--camoColor);
        font-size: 20px;
        font-weight: 800;
        line-height: 22px;
        text-align: center;

        @media(--viewportMedium) {
          text-align: center;
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
        }
      }

      & > div:last-child {
        color: var(--huntingOrange);
        font-size: 20px;
        line-height: 25px;
        display: none;

        & a, & a:hover, & a:visited {
          color: var(--huntingOrange);
        }

        @media(--viewportMedium) {
          display: unset;
        }
      }
    }
  }
}

.categoryItemsContainer {
  display: flex;
  justify-content: center;
  align-items: end;
  overflow-x: hidden;
  gap: 1em;

  @media(--viewportMedium) {
    overflow-x: auto;
  }
}

.categoryLink {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-width: 120px;
  max-width: 120px;

  & >div:first-child {
    height: 0;

    @media(--viewportMedium) {
      height: 45px;
    }
  }

  & div:has(>svg) {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.75);

    @media(--viewportMedium) {
      transform: unset;
    }

    & svg {
      color: #bfbfbf;
      stroke: #bfbfbf;
    }
  }

  & div:has(>img) {
    & img {
      width: 120px;
      height: 100px;
      object-fit: cover;
      /* border-top-left-radius: 12px; */
      /* border-top-right-radius: 12px; */

      &.categorySelected {
        border-radius: 12px;
      }

      @media(--viewportMedium) {
        width: 150px;
      }
    }
  }

  & > .categoryLabel {
    background: transparent;
    color: var(--colorWhite);
    font-size: 15px;
    line-height: 15px;
    font-weight: 700;
    height: 100%;
    border-bottom: 5px solid transparent;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media(--viewportMedium) {
      height: unset;
    }
  }

  &:hover, &.categoryLinkSelected {
    text-decoration: none;

    & div:has(>svg) {
      & svg {
        color: var(--huntingOrange);
        stroke: var(--huntingOrange);
      }
    }

    & > .categoryLabel {
      border-color: var(--huntingOrange);
    }
  }
}

.mobileSort {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid var(--colorGrey100);
}

.mapBtn {
  position: fixed;
  bottom: 28px;
  width: fit-content;
  min-height: 30px;
  padding: 5px 10px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5em;

  @media(--viewportMedium) {
    display: none;
  }
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--colorGrey100);
  margin-top: 36px;
}

.modalHeading {
  composes: h1 from global;
  font-size: 32px;
  line-height: 32px;
  margin-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.filtersWrapper {
}

.showListingsContainer {
  width: 100%;
  background: var(--colorWhite);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 24px;
}

.settingsBtnHandler {
  display: none;

  @media(--viewportMedium) {
    display: unset;
  }

  padding: 0 1em;

  & button {
    background: transparent;
    border: 1px solid #bfbfbf;
    width: 55px;
    height: 50px;
    border-radius: 55px;
    padding: 0;
    position: relative;

    & span {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: #bfbfbf;
      color: var(--huntingOrange);
      border-radius: 25px;
      width: 25px;
      max-width: 25px;
    }

    & svg {
      color: #bfbfbf;
      width: 30px;
    }

    &:hover {
      border: 1px solid var(--huntingOrange);
      background: transparent;

      & svg {
        color: var(--huntingOrange);
      }
    }
  }
}

.categoriesBar {
  width: 100%;
  top: 60px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 25px 0 0;
  display: flex;
  justify-content: center;

  @media(--viewportMedium) {
    padding: 10px 0 0;
  }
}

.categoriesScroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 1rem;
  scrollbar-width: none;
}

.categoriesScroll::-webkit-scrollbar {
  display: none;
}


.categoryLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  min-width: 80px;
}

.categoryIcon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #f5f5f5; */
  border-radius: 50%;
  padding: 8px;
}

.icon {
  width: 36px;
  height: 36px;
  color: #666;
}

.categoryLinkSelected .categoryIcon {
  background: black;
}
.categoryContainerLinkSelected .categoryIcon img {
  /* background: var(--huntingOrange); */
  filter: contrast(100%);
}

.categoryLinkSelected .icon {
  color: white;
}

.categoryContainerLinkSelected, .subcategoryLinkSelected {
  & > .categoryLabel {
    border-color: var(--huntingOrange);
  }
}

.subcategoryLinkSelected .categoryIcon img {
  /* background: var(--huntingOrange); */
  filter: contrast(100%);
}

.subcategoryLinkSelected .icon {
  color: white;
}

.categoryIcon img {
  max-width: 32px;
  max-height: 32px;
  width: auto;
  height: auto;
  object-fit: contain;
  filter: contrast(60%);
}

.categoryNameLabel {
  font-size: 10px;
  width: 100%;
  text-align: center;
  white-space: break-spaces;
  line-height: 14px;
  margin: 0;
  padding: 0;
}

.toggleMap {
  margin: 2em auto 0;
  width: fit-content;
  padding: 0.5em 1em;
  font-size: 12px;
  min-height: unset;
  height: fit-content;
  border-radius: 5em;
  position: sticky;
  bottom: 2.2em;
  z-index: 10;
}
